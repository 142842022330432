/*
 * Hamburger Menu
 */

jQuery(document).ready(function($) {
     $('.hamburger').click(function(){
        $(this).toggleClass('is-active');
        $('.nav').toggleClass('is-active');

        $('.nav').addClass('animate');

        window.setTimeout(function(){
            $('.nav').removeClass('animate');
        }, 400);

        $('body').toggleClass('is-active');
    });
});