/*
 * Starts any clocks using the user's local time
 * From: cssanimation.rocks/clocks
 */
function initLocalClocks() {
  // Get the local time using JS
  var date = new Date;
  var seconds = date.getSeconds();
  var minutes = date.getMinutes();
  var hours = date.getHours();

  // Create an object with each hand and it's angle in degrees
  var hands = [
    {
      hand: 'clock--hours',
      angle: (hours * 30) + (minutes / 2)
    },
    {
      hand: 'clock--minutes',
      angle: (minutes * 6)
    }
  ];
  // Loop through each of these hands to set their angle
  for (var j = 0; j < hands.length; j++) {
    var elements = document.querySelectorAll('.' + hands[j].hand);
    for (var k = 0; k < elements.length; k++) {
        elements[k].style.webkitTransform = 'rotateZ('+ hands[j].angle +'deg) translate(-50%, -1px)';
        elements[k].style.transform = 'rotateZ('+ hands[j].angle +'deg) translate(-50%, -1px)';
        // If this is a minute hand, note the seconds position (to calculate minute position later)
        if (hands[j].hand === 'minutes') {
          elements[k].parentNode.setAttribute('data-second-angle', hands[j + 1].angle);
        }
    }
  }
}

/*
 * Set a timeout for the first minute hand movement (less than 1 minute), then rotate it every minute after that
 */
function setUpMinuteHands() {
    // Find out how far into the minute we are
    var containers = document.querySelectorAll('.minutes-container');
    setTimeout(function() {
      moveMinuteHands(containers);
    }, 60);
}

/*
 * Do the first minute's rotation
 */
function moveMinuteHands(containers) {
  for (var i = 0; i < containers.length; i++) {
    containers[i].style.webkitTransform = 'rotateZ(6deg) translate(-50%, -1px)';
    containers[i].style.transform = 'rotateZ(6deg) translate(-50%, -1px)';
  }
  // Then continue with a 60 second interval
  setInterval(function() {
    for (var i = 0; i < containers.length; i++) {
      if (containers[i].angle === undefined) {
        containers[i].angle = 12;
      } else {
        containers[i].angle += 6;
      }
      containers[i].style.webkitTransform = 'rotateZ('+ containers[i].angle +'deg) translate(-50%, -1px)';
      containers[i].style.transform = 'rotateZ('+ containers[i].angle +'deg) translate(-50%, -1px)';
    }
  }, 60000);
}

(function(){
    initLocalClocks();
    setUpMinuteHands();
})();

jQuery(document).ready(function($) {
    $('.hours-of-operations--top').click(function(){
        let t = $(this);

        if (!t.hasClass('open')) {
            t.addClass('open').find('.hours-of-operation').toggleClass('open');
        }
        else{
            if (t.hasClass('open') && $('.hours-of-operation').hasClass('open')) {
                $('.hours-of-operation').removeClass('open');
                $('.hours-of-operations--top').removeClass('open');
            }
        }
    });

    $('.hours-of-operation button').click(function(){
        $('.hours-of-operation').removeClass('open');

        window.setTimeout(function(){
            $('.hours-of-operations--top').removeClass('open');
        }, 200);

    });
});